import React from "react"
import { MasterLayoutScene } from "@bep43vn/scenes"
import { LocationRoute } from "@bep43vn/pages/page-routing"
import { CategorizeCard } from "@bep43vn/windgets/categorize-card"
import * as styles from "./index-reset.module.scss"

export const BepHutKhoiKhuMuiPage = () => {
  return (
    <MasterLayoutScene
      collectionName=""
      collectionHref={LocationRoute.HomePage}
    >
      <div className={styles.root}>
        <CategorizeCard
          title="Điều hòa"
          imageThumbnail="/images/trang-chu/dieu-hoa.jpg"
          productDetailHref={LocationRoute.DieuHoa}
        ></CategorizeCard>
        <CategorizeCard
          title="Máy ép trái cây"
          imageThumbnail="/images/trang-chu/may-ep-trai-cay.jpg"
          productDetailHref={LocationRoute.MayEpTraiCay}
        ></CategorizeCard>
        <CategorizeCard
          title="Máy xay sinh tố"
          imageThumbnail="/images/trang-chu/may-xay-sinh-to.jpg"
          productDetailHref={LocationRoute.MayXaySinhTo}
        ></CategorizeCard>
        <CategorizeCard
          title="Nồi cơm điện"
          imageThumbnail="/images/trang-chu/noi-com-dien.jpg"
          productDetailHref={LocationRoute.NoiComDien}
        ></CategorizeCard>
        <CategorizeCard
          title="Bếp - Hút khói - Khử mùi"
          imageThumbnail="/images/trang-chu/bep-hut-khoi-khu-mui.jpg"
          productDetailHref={LocationRoute.BepHutKhoiKhuMui}
        ></CategorizeCard>
        <CategorizeCard
          title="Nồi thủy tinh Visions"
          imageThumbnail="/images/trang-chu/noi-thuy-tinh-visions.jpg"
          productDetailHref={LocationRoute.NoiThuyTinhVisions}
        ></CategorizeCard>
        <CategorizeCard
          title="Nồi men Fujihoro"
          imageThumbnail="/images/trang-chu/noi-men-furihoro.jpg"
          productDetailHref={LocationRoute.NoiMenFujihoro}
        ></CategorizeCard>
        <CategorizeCard
          title="Nồi chiên không dầu"
          imageThumbnail="/images/trang-chu/noi-chien-khong-dau.jpg"
          productDetailHref={LocationRoute.NoiChienKhongDau}
        ></CategorizeCard>
        <CategorizeCard
          title="Quạt"
          imageThumbnail="/images/trang-chu/quat.jpg"
          productDetailHref={LocationRoute.Quat}
        ></CategorizeCard>
        <CategorizeCard
          title="Gia dụng khác"
          imageThumbnail="/images/trang-chu/gia-dung-khac.jpg"
          productDetailHref={LocationRoute.GiaDungKhac}
        ></CategorizeCard>
      </div>
    </MasterLayoutScene>
  )
}

export default BepHutKhoiKhuMuiPage
