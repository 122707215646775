import React from "react"
import * as styles from "./categorize-card.module.scss"
import { CategorizeCardPropsType } from "./categorize-card.d"

export const CategorizeCard: React.FCWithRoute<
  CategorizeCardPropsType
> = props => {
  const {
    title = "",
    imageThumbnail = "https://product.hstatic.net/1000292693/product/141443757_3182953025137457_665201410040199607_n_2094fa96a348414ebc3f4c859b6b6a21_large.jpg",
    productDetailHref = "/",
  } = props
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div></div>
        <div className={styles.card}>
          <div className={styles.thumbnail}>
            <a title={title} href={productDetailHref}>
              <img width={"100%"} src={imageThumbnail} alt={title}></img>
            </a>
          </div>
          <div className={styles.productName}>
            <a href={productDetailHref} title={title}>
              {title}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
