// extracted by mini-css-extract-plugin
export var root = "categorize-card-module--root--WPMyD";
export var container = "categorize-card-module--container--RiL7O";
export var card = "categorize-card-module--card--ZQq3m";
export var thumbnail = "categorize-card-module--thumbnail--aW07C";
export var thumbnailImage = "categorize-card-module--thumbnailImage--MBitB";
export var productInfor = "categorize-card-module--productInfor--NXil1";
export var productName = "categorize-card-module--productName--FPiH4";
export var priceBox = "categorize-card-module--priceBox--4LDd-";
export var comparePrice = "categorize-card-module--comparePrice--ZXyKr";
export var productSale = "categorize-card-module--productSale--HSZka";